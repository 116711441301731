import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ApiSavedSearch } from 'api/savedSearch/types/ApiSavedSearch';
import { getSearchPropertiesFromState } from 'modules/search/helpers/savedSearch/getSearchPropertiesFromState';
import { removeUnsaveableFilters } from 'modules/search/helpers/savedSearch/removeUnsaveableFilters';
import { searchesAreEqual } from 'modules/search/helpers/savedSearch/searchesAreEqual';
import { serializeFiltersForSavedSearch } from 'modules/search/helpers/savedSearch/serializeFiltersForSavedSearch';
import { filterToIndex } from 'modules/search/helpers/savedSearches';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import {
  useSearchFilterType,
  useSearchFiltersById,
  useSearchLocation,
  useSearchQuery,
  useSearchRadius,
} from 'modules/search/zustand-stores/searchStore';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';

type Args = {
  searchFacets: SearchFacet[];
};

export function useCurrentSavedSearch({ searchFacets }: Args): {
  savedSearch: ApiSavedSearch | null | undefined;
  savingSearchStatus: 'UNSAVED' | 'EDITING' | 'SAVED';
} {
  const location = useLocation();

  const { user } = useUserEnvironment();

  const query = useSearchQuery();
  const searchFilterType = useSearchFilterType();
  const radius = useSearchRadius();
  const filtersById = useSearchFiltersById();
  const searchLocation = useSearchLocation();

  const savedSearch = useMemo(() => {
    if (!user) return null;

    const searchState = getSearchPropertiesFromState({
      query,
      searchFacets,
      searchLocation,
      radius,
      filtersById,
      searchFilterType,
    });

    const search = removeUnsaveableFilters({
      ...searchState,
      index: filterToIndex(searchFilterType),
      filters: serializeFiltersForSavedSearch({
        filtersById,
        queryParams: queryString.parse(location.search),
        searchFacets,
      }),
    });

    return user.savedSearches.find((s) => searchesAreEqual(s, search));
  }, [
    user,
    query,
    searchFacets,
    searchLocation,
    radius,
    filtersById,
    searchFilterType,
    location.search,
  ]);

  const savingSearchStatus = useMemo(
    () => user?.savingSearchStatus || (savedSearch ? 'SAVED' : 'UNSAVED'),
    [user, savedSearch],
  );

  return {
    savedSearch,
    savingSearchStatus,
  };
}
