import { range } from 'utils/functional/array/range';

import { SearchLoadingSectionMockResultsItem } from './SearchLoadingSectionMockResultsItem';

type Props = {
  hideLabel?: boolean;
  items?: number;
};

export function SearchLoadingSectionMockResults({
  hideLabel,
  items = 10,
}: Props) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      {range(items).map((_, index) => (
        <SearchLoadingSectionMockResultsItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          hideLabel={hideLabel}
        />
      ))}
    </div>
  );
}
