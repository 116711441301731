import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';

export function getSavedSearchObjectForComparison(
  savedSearch: ApiSavedSearchUnsaved,
) {
  const {
    text,
    filters,
    location: {
      geoType,
      stateCode,
      countryCode,
      latitude,
      longitude,
      boundingBoxN,
      boundingBoxS,
      boundingBoxW,
      boundingBoxE,
    },
    radius,
  } = savedSearch;

  return {
    text,
    // For some reason, not spreading the filters causes the result of `deepEqual`
    // to always be false
    ...filters,
    geoType: geoType || null,
    stateCode: stateCode || null,
    countryCode: countryCode || null,
    latitude: latitude ? parseFloat(latitude.toFixed(4)) : null,
    longitude: longitude ? parseFloat(longitude.toFixed(4)) : null,
    boundingBoxN: boundingBoxN ? parseFloat(boundingBoxN.toFixed(4)) : null,
    boundingBoxS: boundingBoxS ? parseFloat(boundingBoxS.toFixed(4)) : null,
    boundingBoxW: boundingBoxW ? parseFloat(boundingBoxW.toFixed(4)) : null,
    boundingBoxE: boundingBoxE ? parseFloat(boundingBoxE.toFixed(4)) : null,
    radius,
  };
}
