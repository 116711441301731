import invariant from 'invariant';

import { isPointGeotype } from './isPointGeotype';

export const radiusStringToInt = (radius: string | null | undefined) => {
  switch (radius) {
    case 'auto':
      return null;
    case 'country':
      return -1;
    case 'state':
      return -2;
    default:
      invariant(radius, 'Search radius is null or undefined');
      return parseInt(radius, 10);
  }
};

export const radiusIntToString = (
  radius: number | null | undefined,
  geoType: string | null | undefined,
): 'auto' | 'country' | 'state' | string | null => {
  if (!radius && isPointGeotype(geoType)) {
    return 'auto';
  }

  if (radius === -1) {
    return 'country';
  }

  if (radius === -2) {
    return 'state';
  }

  return radius ? String(radius) : null;
};
