import { ParsedQuery } from 'query-string';

import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { mapObject } from 'utils/functional';
import { keyBy } from 'utils/functional/array/keyBy';

import { almostSavedSearchSerializableDataStructure } from './almostSavedSearchSerializableDataStructure';

type Args = {
  filtersById: SearchFiltersById;
  queryParams: ParsedQuery;
  searchFacets: SearchFacet[];
};

// serializeFiltersForSavedSearch takes the redux filter state and outputs
// a saved-search-serializable data structure. See `stateSearchFilters`
export function serializeFiltersForSavedSearch({
  filtersById,
  queryParams,
  searchFacets,
}: Args) {
  const searchFacetById = keyBy(searchFacets, 'id');

  const data = almostSavedSearchSerializableDataStructure({
    filtersById,
    searchFacets,
  });

  return mapObject(
    // @ts-expect-error fix types
    data,
    (key: SearchFacetId, value) => {
      if (['type', 'startsLT', 'endsGT'].includes(key)) {
        return [key, value];
      }

      if (searchFacetById[key].type === 'string') {
        return [
          searchFacetById[key].name,
          queryParams[searchFacetById[key].name] || value,
        ];
      }

      return [searchFacetById[key].name, value];
    },
  );
}
