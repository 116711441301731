import cloneDeep from 'lodash/cloneDeep';

import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';

export function removeUnsaveableFilters(search: ApiSavedSearchUnsaved) {
  const searchCloned = cloneDeep(search);
  // some action types are not saveable
  if (
    searchCloned.filters.type === 'VOLOP' &&
    searchCloned.filters.actionType
  ) {
    searchCloned.filters.actionType = searchCloned.filters.actionType.filter(
      (at) => !['DONATION', 'LOAN', 'PETITION'].includes(at),
    );
  }
  return searchCloned;
}
