import { radiusStringToInt } from 'modules/search/helpers/radius';
import { filterToIndex } from 'modules/search/helpers/savedSearches';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';

import { almostSavedSearchSerializableDataStructure } from './almostSavedSearchSerializableDataStructure';

type Args = {
  query: string;
  searchFilterType: SearchType;
  filtersById: SearchFiltersById;
  searchLocation: SearchLocation | undefined;
  radius: string | undefined;
  searchFacets: SearchFacet[];
};

export function getSearchPropertiesFromState({
  query,
  searchFilterType,
  filtersById,
  searchLocation,
  radius,
  searchFacets,
}: Args) {
  const { text, ...locationWithoutText } = searchLocation || {};
  return {
    filters: almostSavedSearchSerializableDataStructure({
      filtersById,
      searchFacets,
    }),
    location: {
      text,
      ...locationWithoutText,
    },
    text: query,
    radius: radiusStringToInt(radius),
    index: filterToIndex(searchFilterType),
  };
}
