import { ApiSavedSearch } from 'api/savedSearch/types/ApiSavedSearch';
import { Form } from 'components/Form/Form';
import {
  editSavedSearch,
  updateSavingSearchStatus,
} from 'store/ducks/userEnvironment.actions';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { trackEvent } from 'utils/analytics/track/trackEvent';

import { EditSavedSearchForm } from './EditSavedSearchForm';
import { EditSavedSearchFormValues } from './EditSavedSearchFormValues';

type Props = {
  savedSearch: ApiSavedSearch;
};

export function SaveSearchButtonTooltip({
  savedSearch: { id, name, notifyByEmail },
}: Props) {
  const dispatch = useMainDispatch();

  const onSubmit = async ({
    values,
  }: {
    values: EditSavedSearchFormValues;
  }) => {
    dispatch(editSavedSearch(id, values));
    dispatch(updateSavingSearchStatus('SAVED'));
    trackEvent(
      values.notifyByEmail
        ? 'Opted into email alerts'
        : 'Opted out of email alerts',
      {
        context: 'Modal',
      },
    );
    if (values.name !== name) {
      trackEvent('Updated Saved Search Name', {
        context: 'Modal',
      });
    }
  };

  return (
    <div data-qa-id="edit-saved-search-tooltip">
      <Form
        analyticsTitle="Edit Saved Search"
        initialValues={{
          name,
          notifyByEmail,
        }}
        usageContext="modal"
        data-qa-id="edit-saved-search-form"
        onSubmit={onSubmit}
        render={() => <EditSavedSearchForm />}
      />
    </div>
  );
}
